import React, { useContext, useEffect, useRef } from 'react';
import { AuthContext } from 'context/AuthContext';
import { useNavigate, Routes, Route, useSearchParams } from 'react-router-dom';
import { dispatchSetTranslations } from 'context/reducerActions';
import useTournamentAPI from 'apis/useTournamentAPI';
import processPostMessage, { handleParams } from 'services/processPostMessage/ProcessPostMessage';
import Tournaments from '../../pages/Tournaments';
import TournamentSingle from '../../pages/TournamentSingle';

const Content = () => {
  const [state, dispatch] = useContext(AuthContext);
  const isGetTranslations = useRef(false);
  const { languageId, authToken } = state;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getTranslations } = useTournamentAPI();

  useEffect(() => {
    processPostMessage({ dispatch, navigate });
    handleParams({ searchParams, setSearchParams, dispatch });
  }, []);

  useEffect(() => {
    const start = async () => {
      const translations = await getTranslations();
      const tmpObj = {};
      if (translations?.hasError === false) {
        translations.data.forEach((translation) => {
          tmpObj[translation.translationId] = translation.text;
        });
        dispatchSetTranslations(dispatch, tmpObj);
      }
    };

    if (authToken && isGetTranslations.current === false) {
      isGetTranslations.current = true;
      start();
    }
  }, [languageId, authToken]);

  return authToken && (
    <Routes>
      <Route exact path='/' Component={Tournaments} />
      <Route exact path='/tournament/:tournamentId/Periods/:periodId' Component={TournamentSingle} />
      <Route exact path='/tournament/:tournamentId/Periods/:periodId/gameId/:gameId/playType/:playType' Component={TournamentSingle} />
    </Routes>
  );
};


export default Content;
