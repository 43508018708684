import React, {memo, useEffect} from 'react';
import classNames from 'classnames';
import {WIDGET_STATUS_ENDED, WIDGET_STATUS_LIVE, WIDGET_STATUS_UPCOMING} from 'enums/tournament';
import styles from '../styles.module.less';
import {msgToParent} from '../../../services/processPostMessage/ProcessPostMessage';

const Filter = ({translations, onFilter, filterStatus}) => {
  const ACTION_LIVE = '#live';
  const ACTION_UPCOMING = '#upcoming';
  const ACTION_ENDED = '#ended';

  const labelsList = {
    [ACTION_LIVE]: WIDGET_STATUS_LIVE,
    [ACTION_UPCOMING]: WIDGET_STATUS_UPCOMING,
    [ACTION_ENDED]: WIDGET_STATUS_ENDED,
  };

  const actionsList = [
    {
      state: null,
      name: translations?.allTournaments,
      label: '',
    },
    {
      state: WIDGET_STATUS_LIVE,
      name: translations?.liveTournaments,
      label: ACTION_LIVE,
    },
    {
      state: WIDGET_STATUS_UPCOMING,
      name: translations?.upcomingTournaments,
      label: ACTION_UPCOMING,
    },
    {
      state: WIDGET_STATUS_ENDED,
      name: translations?.endedTournaments,
      label: ACTION_ENDED,
    },
  ];

  const handleFilter = (state, label) => {
    onFilter(state);
    msgToParent({type: 'currentURL', value: encodeURIComponent(label)});
  };

  useEffect(() => {
    if (labelsList[window.location.hash]) {
      onFilter(labelsList[window.location.hash]);
    }
  }, []);

  return (
    <div className={styles.tournamentListTabs}>
      {
        actionsList.map(item => (
          <button
            key={item.state}
            type='button'
            onClick={() => handleFilter(item.state, item.label)}
            className={classNames({[styles.tabActive]: filterStatus === item.state})}
          >
            {item.name}
          </button>
        ))
      }
    </div>
  );
};

export default memo(Filter);