import React, {useContext, useEffect, useRef, useState} from 'react';
import DummyImg from 'assets/img/dummy-img.png';
import {PlayModeIcon} from 'lib/Icons';
import {msgToParent, updateParentHeight} from 'services/processPostMessage/ProcessPostMessage';
import {PLAYER_STATUS_JOINED, WIDGET_STATUS_LIVE} from 'enums/tournament';
import {AuthContext} from 'context/AuthContext';
import Button from 'components/Button/Button';
import classNames from 'classnames';
import GameLaunchModal from './GameLaunchModal';
import styles from '../../styles.module.less';
import PopConfirm from '../../../../components/PopConfirm/PopConfirm';
import {useVerifyAndJoin} from '../../../../components/JoinButton/hooks';

const PLAY_TYPE_REAL = 'real';
const PLAY_TYPE_DEMO = 'demo';

export default (props) => {
  const {game, tournament, deviceType, gameIdParam, playTypeParam, testEnv} = props;
  const [state] = useContext(AuthContext);
  const imageRef = useRef(null);

  const [modalVisible, setVisibility] = useState(null);
  const isDesktop = deviceType === 1;
  const {joinedStatus} = tournament;

  const [popconfirmProps, setPopconfirmProps] = useState({
    open: false,
    text: '',
    hasJoinPopUp: false,
    hasCancelButton: false,
    onConfirm: () => {
    },
  });

  const onCancel = () => {
    setPopconfirmProps({...popconfirmProps, open: false});
  };

  const isFromGames = true;

  const openGame = mode => {
    setVisibility({mode, gameName: game.name});
    document.body.style.overflow = 'hidden';
    updateParentHeight({customHeight: 'calc(100vh - 136px)'});

    const path = `/tournament/${tournament.id}/Periods/${tournament.periodId}/gameId/${game.id}/playType/${mode}`;
    msgToParent({type: 'currentURL', value: encodeURIComponent(path)});
  };

  const {
    handleJoin,
  } = useVerifyAndJoin({
    tournament,
    open: popconfirmProps.open,
    setPopconfirmProps,
    isFromGames,
    openGame,
    playerLoggedIn: tournament?.playerLoggedIn,
  });

  const handleGameLaunch = (mode) => {
    if (testEnv) return true;

    if ((mode === PLAY_TYPE_REAL && tournament?.playerLoggedIn) || mode === PLAY_TYPE_DEMO) {
      if (
        joinedStatus === PLAYER_STATUS_JOINED ||
        mode === PLAY_TYPE_DEMO ||
        tournament?.status !== WIDGET_STATUS_LIVE
      ) {
        openGame(mode);
      } else {
        setPopconfirmProps({
          open: true,
          text: state.translations?.toCollectPointsJoinTheTournamentBeforePlayingTheGame,
          okText: state.translations?.join,
          hasCancelButton: true,
          joinPopUp: false,
          onConfirm: () => {
            setPopconfirmProps(state => ({
              ...state,
              open: false,
            }));
            handleJoin();
          },
        });
      }
    } else {
      msgToParent({type: 'openLogin'});
    }
  };

  const handleGameLaunchClose = () => {
    setVisibility(null);
    document.body.style.overflow = 'unset';
    updateParentHeight();
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    imageRef.current.focus();
  };

  useEffect(() => {
    if(game.id == gameIdParam){
      handleGameLaunch(playTypeParam);
    }
  }, [])

  return (
    <>
      <div
        className='col-16 col-xl-20 col-lg-25 col-md-33 col-sm-50 col-gutter-16 col-gutter-lg-12 col-gutter-md-12 col-gutter-sm-8'>
        <div className={styles.tournamentSingleGame}>
          <div
            className={classNames(styles.tournamentSingleGameImage, isDesktop ? '' : styles.tournamentSingleGameImageMobile)}
            ref={imageRef} onTouchStart={handleTouchStart}>
            <img src={game.imageUrl ?? DummyImg} alt='' />
            <div className={styles.tournamentSingleGameActions}>
              <div className={styles.tournamentSingleGameActionsBtn}>
                <PopConfirm
                  icon={null}
                  {...popconfirmProps}
                  onCancel={onCancel}
                  isFromGames
                  setPopconfirmProps={setPopconfirmProps}
                >
                  <Button
                    type='button'
                    className='playBtn'
                    onClick={() => handleGameLaunch(PLAY_TYPE_REAL)}
                  >
                    <PlayModeIcon />
                    <span>{state.translations?.play}</span>
                  </Button>
                </PopConfirm>

                <Button
                  type='button'
                  className='demoBtn'
                  onClick={() => handleGameLaunch(PLAY_TYPE_DEMO)}
                >
                  {state.translations?.demo}
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.tournamentSingleGameTitle}>{game.name}</div>
          <div className={styles.tournamentSingleGameSubTitle}>{game.providerName}</div>
        </div>
      </div>

      {modalVisible !== null && (
        <GameLaunchModal
          gameId={game.id}
          mode={modalVisible.mode}
          gameName={modalVisible.gameName}
          onClose={handleGameLaunchClose}
        />
      )}
    </>
  );
}