export const PLAYER_STATUS_JOINED = 1;
export const PLAYER_STATUS_UNJOINED = 2;
export const PLAYER_STATUS_REMOVED = 3;

export const WIDGET_STATUS_DRAFT = 1;
export const WIDGET_STATUS_UPCOMING = 2;
export const WIDGET_STATUS_PENDING = 2;
export const WIDGET_STATUS_LIVE = 3;
export const WIDGET_STATUS_ENDED = 4;
export const WIDGET_STATUS_CALCULATED = 5;
export const WIDGET_STATUS_CANCELED = 6;

export const TYPE_SINGLE = 1;
export const TYPE_PERIODIC = 2;

export const ACCESS_TYPE_PUBLIC = 1;
export const ACCESS_TYPE_PRIVATE = 2;


export const getPlayerStatus = (playerStatus, state) => {
  const PLAYER_STATUS = {
    [PLAYER_STATUS_JOINED]: state.translations?.joined,
    [PLAYER_STATUS_UNJOINED]: state.translations?.joinTournament,
    [PLAYER_STATUS_REMOVED]: state.translations?.joinTournament,
  };

  return PLAYER_STATUS[playerStatus];
};
export const getWidgetStatus = (playerStatus, state) => {
  const PLAYER_STATUS = {
    [WIDGET_STATUS_LIVE]: state.translations?.live,
    [WIDGET_STATUS_UPCOMING]: state.translations?.upcoming,
    [WIDGET_STATUS_ENDED]: state.translations?.ended,
    [WIDGET_STATUS_CALCULATED]: state.translations?.ended,
    [WIDGET_STATUS_CANCELED]: state.translations?.cancelled,
  };

  return PLAYER_STATUS[playerStatus];
};
